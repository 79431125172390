<template>
  <div>
    <div class="card primary">
      <div class="card-body p-5">
        <h3 class="card-title text-center">
          Passwort {{ isCreate ? 'erstellen': 'zurücksetzen' }}
        </h3>
        <p
          class="text-center"
        >
          Bitte geben Sie ihr neues Passwort ein
        </p>
        <ca-alert
          v-if="passwordResetError"
          variant="danger"
          rounded
          :message="passwordResetError"
        />

        <template>
          <form
            novalidate
            @submit.prevent="resetPassword()"
          >
            <basic-input
              id="inputPassword1"
              v-model="password1"
              type="password"
              required
              label="Neues Passwort"
            />
            <basic-input
              id="inputPassword2"
              v-model="password2"
              type="password"
              required
              label="Passwort wiederholen"
            />
            <span
              v-if="passwordInvalid"
              class="text-danger"
            >Die Passwörter stimmen nicht miteinander überein.</span>
            <button
              :disabled="passwordResetPending || passwordInvalid"
              type="submit"
              class="btn btn-primary text-white w-100 mt-3"
            >
              <span v-if="!passwordResetPending">
                Passwort {{ isCreate ? 'erstellen': 'zurücksetzen' }}
              </span>
              <i
                v-if="passwordResetPending"
                class="fal fa-circle-notch fa-spin"
              />
            </button>
          </form>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import feathers from '@/api'
import BasicInput from '@/components/BaseComponents/BasicInput'

export default {
  name: 'PasswordForgot',
  $_veeValidate: {
    validator: 'new' // give me my own validator scope.
  },
  components: {
    BasicInput
  },
  data () {
    return {
      password1: '',
      password2: '',
      passwordResetPending: false,
      passwordResetError: null
    }
  },
  computed: {
    passwordInvalid () {
      return this.password1 && this.password2 && this.password1 !== this.password2
    },
    isCreate () {
      return !!this.$route.meta.isCreate
    }
  },
  methods: {
    async resetPassword () {
      try {
        const valid = await this.$validator.validateAll()
        if (!valid || this.passwordResetPending) {
          return
        }
        this.passwordResetPending = true
        await feathers.service('auth-management').create({
          passwordResetToken: this.$route.params.passwordResetToken,
          password: this.password1
        },
        {
          query: {
            $client: {
              action: 'sendPasswordReset'
            }
          }
        })
        await this.$router.push({ name: 'Login', query: { successType: 'passwordReset' } })
      } catch (error) {
        this.passwordResetError = error
        console.error(error)
      } finally {
        this.passwordResetPending = false
      }
    }
  }
}
</script>

<style scoped lang="scss">

</style>
